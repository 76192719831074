import messages from '@intlify/unplugin-vue-i18n/messages'

export type Locale = 'fr' | 'nl' | 'en'

export const SUPPORTED_LOCALES: Locale[] = ['fr', 'nl', 'en']

export const DEFAULT_LOCALE: Locale = 'fr'

export const getLocaleFromURL = (): string | null => {
  const path = window.location.pathname // Get the path from the URL

  // Extract the locale from the path using a regular expression
  const regex = /^\/([^/]+)/ // Assumes the locale is the first part of the path
  const match = path.match(regex)

  // Return the extracted locale or null if not found
  return match && match[1] ? match[1] : null
}

export const allowedLocale = (localCandidate: string): boolean => SUPPORTED_LOCALES.includes(localCandidate as Locale)

export const setLocale = () => {
  // Get locale if user select one or get locale from user data or from navigator
  const localCandidate =
    getLocaleFromURL() ||
    localStorage.getItem('selected_locale') ||
    localStorage.getItem('user_locale') ||
    DEFAULT_LOCALE

  // Return localCandidate if it is supported or the default locale
  const locale = allowedLocale(localCandidate) ? localCandidate : DEFAULT_LOCALE
  localStorage.setItem('i18n-locale', locale)

  // Set the HTML lang attribute
  document.querySelector('html')?.setAttribute('lang', locale)

  return locale
}

export const setI18nOptions = {
  legacy: false,
  globalInjection: true,
  locale: setLocale(),
  fallbackLocale: DEFAULT_LOCALE,
  availableLocales: SUPPORTED_LOCALES,
  messages: messages,
  allowComposition: true
}
